@font-face{
    src: url('../fonts/IRANSansWeb.ttf');
    font-family: Iran;
}
body{
    font-family: Iran;
}
.btn-lg {
    font-size: 14pt !important;
}

.priceLabel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    padding-bottom: 3px;
}

small {
    font-size: 10pt;
}

input[type="text"], [type=number] {
    height: 45px;
    background: #F2F2F2;
    border: 1px solid #D2D2D2;
    border-radius: 6px;
    direction: ltr;
    padding: 0 14px ;
    font-weight: bold;
}

input[type="password"]::placeholder, input[type="text"]::placeholder {
    text-align: right;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
.logo{
    width: 70px;
    margin-bottom: 20px
}
.loginContainer{
    margin-top: 100px
}

.currency {
    margin: 20px 0 0 0;
    position: relative;
    text-align: left;
}

/*.currency div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 40px;
}*/

.currency label {
    height: 17px;
    font-family: arial;
    margin: 0 5px
}

.currency img{
    width: 16px
}

.amount {
    margin-top: 20px;
}
.icon {
    display: grid;
}
.price {
    height: 44px;
    border: 1px solid #F3F3F3;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 5%) !important;
    width: 100%;
    display: block;
    line-height: 45px;
    text-align: left;
    padding: 0 10px;
}

.paymentBtn {
    background: linear-gradient(270deg, #4B6EFE 0%, #D3B5FC 100%);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    border: 0;
    line-height: 40px;
    font-size: 18px
}

#hashCode {
    font-size: 12.5px;
    border: 1px #2ca32c solid;
    padding: 7px 5px 4px 5px;
    background: #e4f9df;
    border-radius: 5px;
    text-align: center;
    margin-top: 7px;
    word-wrap: break-word
}
.tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    left: 50%;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

#hashCode:hover .tooltiptext{
    visibility: visible;
    opacity: 1;
}

.paymentEnter {
    width: 50%;
    margin: auto;
}
.paymentInfo {
    border: 1px solid #D2D2D2;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 15px;
}

.successHeader {
    border: 1px solid #D2D2D2;
    border-radius: 15px;
}

.successHeader span{
    font-size: 13px;
}

.paymentContent{
    border: 1px solid #D2D2D2;
    border-radius: 15px;
    margin-top: 30px;
    padding: 20px 0 100px 0;
}

.successContent{
    border: 1px solid #D2D2D2;
    border-radius: 15px;
    margin-top: 30px;
    padding: 20px 10px 100px 10px;
    font-size: 12px;
}

.receipt{
    background: linear-gradient(90.06deg, #BF6BF4 0.05%, #722EBF 99.95%);
    border-radius: 8px;
    border: 0;
    padding: 8px 30px;
    color: #fff;
    font-size: 15px;
    margin-top: 20px;
}

.continue{
    background: #F2F2F2;
    border: 2px solid #2EC28D;
    box-shadow: 0px 5px 4px rgba(55, 0, 134, 0.12);
    border-radius: 8px;
    color: #1B9AD2;
    padding: 6px 22px;
    font-size: 15px;
    margin-top: 10px;
}

.copyright {
    background: #e7ffd3;
    border: 1px dashed rgb(70 206 0 / 50%);
    box-shadow: 0px 2px 4px rgba(158, 158, 158, 0.2);
    border-radius: 24px;
    padding: 14px 18px;
    width: 250px;
    margin: 0 0 20px 0
}

.copyright span {
    color: #19084d;
    font-weight: bold;
    margin-top: 20px;
    font-size: 11px;
}

.copyrightEN{
    background: #e7ffd3;
    border: 1px dashed rgb(70 206 0 / 50%);
    box-shadow: 0px 2px 4px rgba(158, 158, 158, 0.2);
    border-radius: 24px;
    padding: 14px 18px;
    width: 276px;
    margin: 0 auto 20px 0
}

.copyrightEN span {
    color: #19084d;
    font-weight: bold;
    margin-top: 20px;
    font-size: 11px;
}

.dropdown {
    position: absolute;
    left: 15px;
    margin: 0 auto;
    border: 1px solid #dbdbdb;
    min-width: 120px;
    border-radius: 6px;
    height: 45px;
}

.dropdown__text {
    color: #000;
    padding: 5px 10px;
    cursor: pointer;
    line-height: 37px;
}

.dropdown__text:after {
    content: "";
    transition: all .3s;
    border: solid #ccc;
    border-width: 0 1px 1px 0;
    float: right;
    margin-top: 15px;
    margin-left: 10px;
    padding: 5px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);

}

.dropdown.active .dropdown__text:after {
    margin-top:1px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);

}

.dropdown__items {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    max-height: 0px;
    transition: max-height 0.6s ease;
}


.dropdown.active .dropdown__items {
    visibility: visible;
    background: #c6c6c6;
    height: auto;
    max-height: 290px;
    opacity: 1;
    transition: max-height 0.5s, opacity 1s, visibility 2s ease;
    left: 0;
    right: 0;
    border: 1px #c6c6c6 solid;
}

.dropdown__item {
    cursor: pointer;
    padding: 10px 18px;
}

.dropdown__item:not(:last-child) {
    border-bottom: 1px solid #666;

}

.dropdown__item:hover {
    color: white;
    background: #3a4143;
}

.appDescription{
    width: 100%;
    border-radius: 15px;
    padding: 20px 250px;
    border: 1px solid #D2D2D2;
    margin-top: 20px;
    color: #404756;
}

.appSupportedNetwork{
    border: 1px solid #D2D2D2;
    border-radius: 15px;
    width: 49%;
    padding: 0 40px;
    min-height: 432px;
}

.connectWallet{
    border: 1px solid #D2D2D2;
    border-radius: 15px;
    width: 49%;
    padding: 0 40px 40px;
    min-height: 432px;
}
.coinIcon{
    width: 60px;
}

.content{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    min-height: 550px;
    margin-top: 80px
}

.networkName{
    color: #909090;
    width: 75px;
    text-align: left;
    font-size: 13px;
}

.coinIconContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    margin-top: 10px;
}

.footer{
    margin-top: 20px;
    width: 100%;
    height: 50px;
    padding: 0 16px;
    position: absolute;
    bottom: 0;
    background: #19084d;
    color: #fff;
}

.footerEN{
    margin-top: 20px;
    width: 100%;
    padding: 15px;
    position: absolute;
    bottom: 0;
    background: linear-gradient(90deg, #030824 0%, #2E0073 100%);
    color: #fff;
}

#timer{
    margin: 0 20px 0 0;
    color: #a11;
}

.transactionHash{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
    display: inline-block;
    direction: ltr;
}

#latestPrice{
    background: #F2F2F2;
    border: 1px solid #D2D2D2;
    border-radius: 6px;
    color: #8D8D8D;
}

.backButton{
    width: 30px;
    cursor: pointer;
    float: left
}

@media screen and (max-width: 480px) {
    body {
        background: #E5E5E5
    }
    .paymentInfo{
        margin: 20px 0 0 0
    }
    .successHeader{
        margin: 20px 0 0 0
    }
    .footer{
        height: 123px;
        padding: 16px;
        text-align: right;
    }
    .footer > div{
        display: block;
        height: auto;
    }
    .footer > div div{
        margin-bottom: 10px;
    }

    .footerEN{
        height: 123px;
        padding: 16px;
        text-align: left;
    }
    .footerEN > div{
        display: block;
        height: auto;
    }
    .footerEN > div div{
        margin-bottom: 10px;
    }
    .copyrightEN{
        width: auto;
    }
    .appDescription{
        padding: 20px 0;
    }
    .content{
        display: block;
    }
    .connectWallet{
        width: 100%;
    }
    .appSupportedNetwork{
        width: 100%;
        margin-top: 50px;
        min-height: 400px;
        margin-bottom: 20px;
        border: 0;
        padding: 0;
    }
    .coinIconContainer{
        margin-top: 30px;
    }
    .footerEN{
        height: auto;
    }
    .paymentEnter{
        width: 100%;
    }
    .hiddenMobile{
        display: none;
    }
    .cryptoAmount{
        width: 70px;
        font-size: 9px
    }
    .transactionHash{
        width: 70px;
    }
    .backButton{
        margin-left: 20px;
    }
    .statePay{
        font-size: 9px;
    }
    .transactionTable{
        font-size: 12px;
    }
}
w3m-network-button{
    direction: ltr;
}
